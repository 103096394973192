import { ISelectItem } from "../components/select-input/select-input";

export const teamRole: ISelectItem[] = [
    { id: 15, label: 'Team' },
    { id: 10, label: 'Admin' },
    { id: 5, label: 'Super Admin' }
]

export const teamRoleSetting: ISelectItem[] = [
    { id: 15, label: 'Team' },
    { id: 10, label: 'Admin' },
    { id: 5, label: 'Super Admin' },
    { id: 0, label: 'Disable' }
]

export const teamSettingEnableDisable: ISelectItem[] = [
    { id: 15, label: 'Enable' },
    { id: 0, label: 'Disable' },
]